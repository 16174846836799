import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Section,
  ManifestoSection,
  GridContainer,
  ImageContainer,
  LogoContainer,
  Text,
  TwoColumnGrid,
  Column,
  TextContainer,
  ManifestoHeader,
  ManifestoText,
  Title,
} from './styles'

const CalendarPress = () => {
  const { wallStreet, apartment, parents, today, manifesto, mobileManifesto } =
    useStaticQuery(graphql`
      query CalendarPressQuery {
        wallStreet: file(relativePath: { eq: "calendar/wallstreet.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        apartment: file(relativePath: { eq: "calendar/apartment-therapy.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        parents: file(relativePath: { eq: "calendar/parents.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        today: file(relativePath: { eq: "calendar/today.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        manifesto: file(relativePath: { eq: "calendar/manifesto.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        mobileManifesto: file(relativePath: { eq: "calendar/mobile-manifesto.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)
  const pressDetails = [
    {
      image: wallStreet,
      quote: `“The calendar with the cult-like following!”`,
      alt: `Wall Street`,
      id: 'wallStreet',
      href: 'https://www.wsj.com/tech/personal-tech/your-schedules-just-got-crazy-again-try-these-tech-tools-6153a3f?st=a9cwh7iah46sg0z&reflink=desktopwebshare_permalink',
    },
    {
      image: parents,
      quote: `“A must-have for busy families.”`,
      alt: `Parents`,
      id: 'parents',
      href: 'https://www.parents.com/planners-calendars-2025-8764714',
    },
    {
      image: apartment,
      quote: `“Makes life seem significantly easier to manage!”`,
      alt: `Apartment Therapy`,
      id: 'apartment',
      href: 'https://www.apartmenttherapy.com/skylight-digital-calendar-review-37024764',
    },

    {
      image: today,
      quote: `“All you need for your family- it's a calendar, it's chores… autonomy for kids.”`,
      alt: `Today Show`,
      id: 'today',
      href: 'https://www.today.com/video/shop-these-back-to-school-essentials-for-your-kids-190634565882',
    },
  ]
  return (
    <>
      <Section>
        <Title>Skylight Calendar in the spotlight</Title>
        <GridContainer>
          {pressDetails.map((press) => (
            <div key={press.id}>
              <a href={press.href} target="_blank" rel="noopener noreferrer">
                <LogoContainer id={press.id}>
                  <GatsbyImage
                    image={press.image.childImageSharp.gatsbyImageData}
                    alt={press.alt}
                  />
                </LogoContainer>
              </a>
              <Text>{press.quote}</Text>
            </div>
          ))}
        </GridContainer>
      </Section>
      <ManifestoSection>
        <TwoColumnGrid>
          <Column>
            <TextContainer>
              <ManifestoHeader>
                At the <span>heart</span> of it
              </ManifestoHeader>
              <ManifestoText>
                We are dedicated to bringing harmony and joy to every family by removing the pain
                points and friction that break a family down. We're on a mission to liberate
                families from outdated scheduling methods while easing the mental burden of every
                caregiver, one Skylight Calendar at a time.
              </ManifestoText>
            </TextContainer>
          </Column>
          <Column>
            <ImageContainer id="manifesto">
              <GatsbyImage
                image={manifesto.childImageSharp.gatsbyImageData}
                alt="Family in the kitchen with a skylight calendar"
              />
              <GatsbyImage
                image={mobileManifesto.childImageSharp.gatsbyImageData}
                alt="Family in the kitchen with a skylight calendar"
              />
            </ImageContainer>
          </Column>
        </TwoColumnGrid>
      </ManifestoSection>
    </>
  )
}

export default CalendarPress
